import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import {
    BedIcon,
    PromoIcon,
    VacationIcon,
    HotelIcon,
    MealIcon,
    DrinksIcon,
    HeartPlusicon,
    ALLPlusIcon
} from '../../assets/icons/icons'
import './Benefits.css';
import Icons from './Icons';


const Benefits = ({ benefits }) => {
    // Initialize benefits as open by default
    const [openBenefits, setOpenBenefits] = useState(
        benefits.reduce((acc, _, index) => ({ ...acc, [index]: true }), {})
    );
    
    // Items are closed by default
    const [openItems, setOpenItems] = useState({});

    // Toggle function for benefits dropdown
    const toggleBenefit = (index) => {
        setOpenBenefits((prev) => ({
            ...prev,
            [index]: !prev[index]
        }));
    };

    // Toggle function for items dropdown
    const toggleItem = (benefitIndex, itemIndex) => {
        setOpenItems((prev) => ({
            ...prev,
            [`${benefitIndex}-${itemIndex}`]: !prev[`${benefitIndex}-${itemIndex}`]
        }));
    };

    return (
        <div className="amex-benefits-container">
            <h4 className="text-uppercase montserrat-medium mt-10 benefit-header">
                Universal Accor Plus Member Benefits
            </h4>
            <p className="benefit-sub">
                One card gives you exclusive benefits across 20 countries, 1,000
                hotels and over 1,400 restaurants in Asia Pacific.
            </p>
            {benefits.map((benefit, benefitIndex) => (
                <div key={benefitIndex}>
                    <div className="amex-benefits mb-2">
                        <span className="header-text text-white">{benefit.title}</span>
                        <FontAwesomeIcon
                            icon={openBenefits[benefitIndex] ? faChevronUp : faChevronDown}
                            className="text-white"
                            onClick={() => toggleBenefit(benefitIndex)}
                        />
                    </div>
                    {openBenefits[benefitIndex] &&
                        benefit.items.map((item, itemIndex) => (
                            <div key={itemIndex} className="my-4">
                                <div className="d-flex justify-content-between mx-3" style={{color: "#050033", fontSize: "14px"}}>
                                        {/* <img src={require(`../../images/icons/${item.icon}`).default} width="20px" height="20px" alt={item.title} className="mr-2"
                                        /> */}
                                    <span className="montserrat-normal">
                                        <span className="icons mr-2"><Icons name={item.name}/></span>
                                        {item.title}
                                    </span>

                                    
                                    <FontAwesomeIcon
                                        icon={openItems[`${benefitIndex}-${itemIndex}`] ? faChevronUp : faChevronDown}
                                        style={{ cursor: "pointer" }}
                                        className="text-secondary"
                                        onClick={() => toggleItem(benefitIndex, itemIndex)}
                                    />
                                    
                                </div>
                                {openItems[`${benefitIndex}-${itemIndex}`] && (
                                    <div className="mx-3 p-2 montserrat-normal" style={{color: "#221b1a", fontSize: "14px"}}>
                                        {item.description}
                                    </div>
                                )}
                            </div>
                        ))}
                </div>
            ))}
        </div>
    );
};

export default Benefits;
