import React from 'react';
import { ALLPlusIcon, BedIcon, DrinksIcon, HeartPlusIcon, HotelIcon, MealIcon, PromoIcon, VacationIcon } from '../../assets/icons/icons';

const Icons = ({ name }) => {
    const icons = {
        "bed": BedIcon,
        "promo": PromoIcon,
        "vacation": VacationIcon,
        "city": HotelIcon,
        "dine": MealIcon,
        "drinks": DrinksIcon,
        "heart": HeartPlusIcon, // Fixed naming issue
        "all": ALLPlusIcon
    };

    const IconComponent = icons[name]; // Get the corresponding icon component

    return IconComponent ? <IconComponent /> : null; // Render the component if found, otherwise return null
};

export default Icons;
