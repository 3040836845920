export const getUrl=()=>{ 
    var urlPath = window.location.href;
    if(urlPath.indexOf("localhost") !== -1 || urlPath.indexOf("dev-partners") !== -1) {
        return {
            urlRoot : "https://accorstaging.wpstaging.io/",
            dbs_bc  : '1042',
            subaru_bc  : '1094',
            scb_bc: '1227',
            amex_bc: '1232'
        };
    } else {
        return {
            urlRoot : "https://www.accorplus.com/",
            dbs_bc  : '1159',
            subaru_bc  : '1094',
            scb_bc: '1324',
            amex_bc: '1358'
        };
    }
}

export const validEmail = (email) => {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email)) return true;  
    return false;
}

export const validBIN = (bin) => {
    if (!/^\d{6}$/.test(bin)) {
        return true
    }
    return false;
}
export const validCode = (code) => {
    // Regular expression to match an alphanumeric string
    // const alphanumericRegex = /^[A-Za-z]+[0-9]+[A-Za-z]$/;
    const alphanumericRegex = /^[A-Z]{4}[0-9]{4}[A-Z][0-9]+$/;

    if(alphanumericRegex.test(code)) return true;
    return false;
};

export const validVoucherCode = (code) => {
    if (!code) return false; // Handle null or empty input
    const voucherRegex = /^AMEXSGD-[A-Z0-9]{3}-[A-Z0-9]{3}$/;
    return voucherRegex.test(code.trim().toUpperCase());
};

