import React from 'react';

import {GLOBALHEADER_MESSAGE} from "../constants/index";
import logo from "../images/Accor-Plus-logo_2019.png";

class Header extends React.Component  {

    render() {
        const {hasGlobalMessage, bannerClass} = this.props;
        return (
            <React.Fragment>
                <div className="d-flex justify-content-between container my-3">
                    <img className="header-logo" src={logo} alt="Accor Plus Logo"/>
                </div>
                <div class="module mod-page-header theme-default theme-dark no-padding no-border">
                    <div class="component-image" data-autoload-component="image">
                        <div className={`${bannerClass}`}>
                            {hasGlobalMessage && 
                                <h4>{GLOBALHEADER_MESSAGE}</h4>
                            }
                        </div>
                        <div class="overlay"></div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default (Header);