import React from "react";
import { Card } from "react-bootstrap";
import "./ProductCard.css";
import explorerCard from "../../images/banners/Card-Pullman.jpg";
import plus from '../../images/icons/Add.svg'

const ProductCard = () => {
  return (
    <div className="border product-card ">
      <div className="h-100 card">
        
          <img
          src={explorerCard}
          className="card-image"
          />
        
        <div className="card-title text-uppercase py-4 px-4 montserrat-medium product-title">Explorer</div>
        <hr className="mx-4 my-0"/>
        <div className="mx-2 card-body">
          <div className="card-subtitle py-2 text-uppercase montserrat-bold product-subtitle">
              Enjoy Accor Plus Universal Benefits
          </div>
          <div className="card-text product-list mb-1">
            <li className="mx-4 product-text montserrat-medium">1 Stay Plus complimentary night</li> 
            <li className="mx-4 product-text montserrat-medium">Exclusive dining and accommodation discounts</li> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
