import React, {Component} from 'react';
import axios from 'axios';
import Header from '../Header';
import { Accordion, Card } from "react-bootstrap";
import parse from 'html-react-parser';
import LoadingScreen from '../common/loading-screen';

import {SUBARU_BENEFITS, SUBARU_LEAD_EMAIL_CHECK} from '../../constants/index';
import { validEmail, getUrl } from '../helper';

import '../../css/style.css';

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email : '',
            errorMessage : '',
            emailValid : true,
            loading      : false
        }

        this.handleChange   = this.handleChange.bind(this);
        this.handelClick   = this.handelClick.bind(this);
    }
        
    handleChange = (e) => {
        this.setState({
            email        : e.target.value,
            emailValid   : true,
            errorMessage : ''
        });
    };

    handelClick = () => {
        this.setState({ loading : true });
        if(!this.state.email){
            this.setState({ 
                loading      : false,
                emailValid   : false,
                errorMessage : 'Email Address is required.'
            });
            return;
        }
        if(!validEmail(this.state.email)){
            this.setState({ 
                loading      : false,
                emailValid   : false,
                errorMessage : 'Email Address not Valid.'});
            return;
        }
        axios.post(`${SUBARU_LEAD_EMAIL_CHECK}`,{
            email : this.state.email
            })
            .then(response => {
                console.log('response', response.data);
                if (response.status === 200) {
                    if(response.data.coupon_code){
                        const urlRoot = getUrl().urlRoot;
                        const bc = getUrl().subaru_bc;
                        const url =  urlRoot + "au/registration?redirect=F&bc="+bc+"&email="+this.state.email+"&coupon="+response.data.coupon_code;
                        console.log("URL", url);
                        window.location.href = url;
                    }
                    this.setState({ loading : false });
                } 
            }).catch(error => {
                let message = '';
                if(error.response.status){                
                    switch(error.response.status){
                        case 400:
                            message = "We can't seem to proceed…<br/>Our record shows that the email address provided is not valid. <br/>Please contact our Member Services team at <a href='mailto:members.pacific@accorplus.com' target='_blank'>members.pacific@accorplus.com</a> for further assistance.";                      
                            break;
                        case 401:
                            message = "We can't seem to proceed…<br/>Please contact our Member Services team at <a href='mailto:members.pacific@accorplus.com' target='_blank'>members.pacific@accorplus.com</a> for further assistance.";
                            break;
                        case 402:
                            message = "We can't seem to proceed…<br/>Our record shows that you have registered for your complimentary Accor Plus membership. If you require further assistance, please contact our Member Services team at <a href='mailto:members.pacific@accorplus.com' target='_blank'>members.pacific@accorplus.com</a>";
                            break;
                        default:
                            message = error.response.data.error;
                            break;
                    }
                }else{
                    message = "Oops! Something went wrong. Please try again later";
                }
                this.setState({ 
                    emailValid   : false,
                    errorMessage : message,
                    loading      : false
                });
        });
    };

    render() {
        const {errorMessage, emailValid, loading} =this.state;
        var benefits;
        if(SUBARU_BENEFITS){
            benefits = SUBARU_BENEFITS.map((item, index) =>
                    <Card key={index} className="benefits">
                        <Accordion.Toggle as={Card.Header} eventKey={index+1}>
                            <div className="row d-flex ">
                                <div className="mb-0 col-10 col-md-11 accordion-title-text">
                                { item.title}
                                </div>
                                <div className="mb-0 col-2 col-md-1">
                                    <img alt= "plus-symbol" src="/images/plus-symbol.png" width="22" />
                                </div>   
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={index+1}>
                            <Card.Body>
                                <div className="row">
                                    <div className="col-12">
                                        {parse(item.description)}
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                );
        }
        var errrorClass=""; 
        if(!emailValid){
            errrorClass = "validation-error"
        }
        return (
            <div class="hotelSales-wrapper">
                <Header 
                    handleLeaderBoard = {this.handleLeaderBoard}
                    bannerClass = "banner-subaru"/> 
                <div className="container">
                    <div class="mt-5 container">
                        <h2>Courtesy of Subaru, redeem your complimentary Accor Plus membership valued at $1,400</h2>
                        <p>Accor Plus, Asia Pacific’s most expansive travel, dining and lifestyle programme enables you to do more, in Australia and across Asia Pacific.</p>
                        <p>As an Accor Plus member, you get to enjoy complimentary hotel night stays, exclusive Members’ Rate at 10% off the best available room rate, up to 50% off your food bill and more across over 1,000 participating Accor hotels including Sofitel, MGallery, Pullman, Swissotel, Novotel and Mercure.</p>
                        <p>You will also automatically qualify for Elite status with a bonus of 20 Status Nights in ALL - Accor Live Limitless, Accor’s global lifestyle loyalty programme.</p>
                        <p>Your 12-month Accor Plus membership includes:</p>
                        <ul>
                            <li>3 Stay Plus complimentary nights* at your choice of over 1,000 participating Accor hotels in Australia and across Asia Pacific</li>
                            <li>3 x complimentary breakfast vouchers for two people</li>
                            <li>2 x complimentary bottle of wine vouchers</li>
                        </ul>
                    </div>
                    <div className="container accordion-container mt-5 benefits-container mb-5">
                        <Accordion defaultActiveKey="0">
                            {benefits}
                        </Accordion>
                    </div>

                    <div className="container">
                        <h5 class="px-0 py-3">Enter your email address to start experiencing more!</h5>
                        <div class="py-3">
                            {!emailValid && (
                                <div class="mb-4 text-bold text-center">{parse(errorMessage)}</div>
                            )}
                            <input type="text" class={`email-check-input ${errrorClass}`} placeholder="Enter email" onChange={this.handleChange}></input>
                            <button class="email-check-input-submit btn btn-primary mt-2 button theme-primary" onClick={this.handelClick}>Submit <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="icon"><path d="M17.42 10l-6.951-6.95-1.326 1.325 4.687 4.688H2.97v1.874H13.83l-4.687 4.688 1.326 1.326L17.419 10z"></path></svg></button>
                            {loading && (
                                <LoadingScreen/>
                            )}
                        </div>
                    </div>

                    <div class="container terms_conditions mt-4">
                        <p class="title"><strong>Terms and conditions:</strong></p>
                        <p>- This offer is only applicable for eligible individuals as determined by Subaru.</p>
                        {/* <p>- Eligible participants for this promotion are:</p>
                        <ul>
                            <li>(i) Individuals who bought a new vehicle from Subaru (Excludes MY23 Forester Hybrid, MY24 Subaru Crosstrek Hybrid and MY24 Subaru Impreza. Offer excludes Fleet, Government, and rental buyers) and delivered between 26 December 2023 and 29 February 2024;</li>
                            <li>(ii) Aged 18 years and above;</li>
                            <li>(iii) Residents of Australia only.</li>
                        </ul> */}

                        <p>- *Offer available at participating Subaru retailers only while stocks last on selected new and demonstrator vehicles sold between 26/12/23 and 29/02/24 and delivered before 29/02/24. Excludes MY24 Subaru Impreza, MY24 Solterra and MY24 BRZ tS. Offer excludes Fleet, Government, and rental buyers. Accor Voucher redeemable after purchase. Not available in conjunction with any other offer.</p>
                        
                        <p>- The above Promotional Terms and Conditions are specific to this offer and the Accor Plus membership <a href="https://www.accorplus.com/au/about/terms-and-conditions/" target="_blank">Terms and Conditions</a> to the Accor Plus programme apply. For the full Terms and Conditions to the Subaru promotion, please refer to <a href="https://www.subaru.com.au/special-offers/great-aussie-detours-accor" target="_blank">Subaru’s website.</a></p>
                        <p>- Stay or dining exceptions may apply to the standard Accor Plus member benefits, please refer to the <a href="https://www.accorplus.com/au/benefits-exceptions/" target="_blank">member benefits exception list</a> for more details.</p>
                        <p>- *Only 1 Stay Plus complimentary night can be used per stay. Consecutive nights at a single hotel count as one stay.</p>
                        <p>- Please view <a href="https://www.accorplus.com/au/about/faqs/" target="_blank">here</a> for Accor Plus' Frequently Asked Questions.</p>
                        <p>- For any Accor Plus membership enquiries, please contact the Accor Plus Member Services via the <a href="https://accorplushelp.zendesk.com/hc/en-au" target="_blank" rel="noopener">Member Help Centre</a> or via phone call at 1300 36 36 37 during business days between 8am - 7pm.</p>
                        <p class='hidden'>
                            testing cache
                        </p>

                        {/* <p>- DBS Vantage Cardmembers must register their interest for their Accor Plus Explorer membership at <a href="https://dbs.com.sg/vantage" target="_blank">dbs.com.sg/vantage.</a> DBS Vantage Cardmembers will receive a welcome email from Accor Plus within 1 working day once their Accor Plus membership has been successfully registered.</p>
                        <p>- Processing of your Accor Plus Explorer membership will take 2-3 days upon Card open date.</p>
                        <p>- Each Principal DBS Vantage Cardmember is entitled to a 12-month Accor Plus Explorer membership per Card Year. The Accor Plus Explorer membership includes 1 Stay Plus complimentary night stay and other dining and lifestyle member benefits.</p>
                        <p>- Each Principal DBS Vantage Cardmember will also automatically qualify for a minimum of Elite Silver membership status in ALL - Accor Live Limitless, Accor's global lifestyle loyalty programme. This status will be reflected in your Accor ALL App, where you get to redeem the one complimentary night stay (known as Stay Plus).</p>
                        <p>- The full list of participating Accor properties across Asia Pacific can be found at accorplus.com.</p>
                        <p>- All Accor Plus member benefits, including 1 Stay Plus complimentary night, will be valid per Card Year. None of the member benefits will be retroactively accrued.</p>
                        <p>- Standard terms and conditions governing the use of an Accor Plus Explorer membership apply. Please view the <a href="https://www.accorplus.com/sg/about/terms-and-conditions/">Accor Plus membership terms and conditions</a> for more details.</p>    
                        <p>- Stay or dining exceptions may apply to the standard Accor Plus member benefits, please refer to the <a href="https://www.accorplus.com/sg/about/terms-and-conditions/">member benefits exception list</a> for more details.</p>    
                        <p>- For any enquiries on Accor Plus membership, please contact the Accor Plus Member Services team via the <a href="https://accorplushelp.zendesk.com/hc/en-au" target="_blank" rel="noopener">Member Help Centre</a>.</p>     */}
                    </div>
                </div>
            </div>    
        );
    }
}

export default (Home);
