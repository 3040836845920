import au_the_ternary_novotel from '../../images/amex/au-the-ternary-novotel-sydney-darling-harbour.jpg'
import hk_the_enclave from '../../images/amex/hk-the-enclave-the-silveri-hong-kong-mgallery.jpg'
import id_sana_sini_at_pullman from '../../images/amex/Id-sana-sini-at-pullman-jakarta-indonesia.jpg'
import in_food_exchange_novotel from '../../images/amex/in-food-exchange-novotel-hyderabad-convention-centre.jpg'
import my_kwee_zeen_sofitel from '../../images/amex/my-kwee-zeen-sofitel-kuala-lumpur-damansara.jpg'
import nz_harbour_society from '../../images/amex/nz-harbour-society-so-auckland.jpg'
import ph_spectrum_fairmont from '../../images/amex/Spectrum-at-Fairmont-Makati.PNG'
import sg_skai_swissotel from '../../images/amex/sg-skai-swissotel-the-stamford.jpg'
import th_belga_rooftop_bar from '../../images/amex/th-belga-rooftop-bar-sofitel-bangkok-sukhumvit.jpg'
import vn_mezz_restaurant from '../../images/amex/vn-mezz-restaurant-sofitel-saigon-plaza.jpg'


export const RESTAURANTS = [
    {
        name: "The Ternary – Novotel Sydney Darling Harbour, Australia",
        image: au_the_ternary_novotel,
        className: "img-400",
        link: "https://www.accorplus.com/sg/restaurants/?destination=THE%20TERNARY%20RESTAURANT&lat=-33.872715&long=151.197555&radius=100&unit=KM"
    },
    {
        name: "The Enclave – The Silveri Hong Kong – MGallery, Hong Kong",
        image: hk_the_enclave,
        className: "img-455",
        link: "https://www.accorplus.com/sg/restaurants/?destination=The%20Silveri%20Hong%20Kong%20-%20MGallery&lat=22.290521&long=113.941118&radius=100&unit=KM"
    },
    { 
        name: "Sana Sini – Pullman Jakarta Indonesia, Indonesia",
        image: id_sana_sini_at_pullman,
        className: "img-455",
        link: "https://www.accorplus.com/sg/restaurants/?destination=SANA%20SINI%20RESTAURANT&lat=-6.193499&long=106.823695&radius=100&unit=KM"
    },
    {
        name: "Food Exchange – Novotel Hyderabad Convention Centre, India",
        image: in_food_exchange_novotel,
        className: "img-400-top",
        link: "https://www.accorplus.com/sg/restaurants/?destination=Novotel%20Hyderabad%20Convention%20Centre&lat=17.47269&long=78.37258&radius=100&unit=KM"
    },
    {
        name: "Kwee Zeen – Sofitel Kuala Lumpur Damansara, Malaysia",
        image: my_kwee_zeen_sofitel,
        className: "img-400",
        link: "https://www.accorplus.com/sg/restaurants/?destination=Sofitel%20Kuala%20Lumpur%20Damansara&lat=3.14675&long=101.661444&radius=100&unit=KM"
    },
    {
        name: "Harbour Society – SO/ Auckland, New Zealand",
        image: nz_harbour_society,
        className: "img-455",
        link: "https://www.accorplus.com/sg/restaurants/?destination=HARBOUR%20SOCIETY&lat=-36.845421&long=174.768507&radius=100&unit=KM"
    },
    {
        name: "Spectrum – Fairmont Makati, Philippines",
        image: ph_spectrum_fairmont,
        className: "img-400",
        link: "https://www.accorplus.com/sg/restaurants/?destination=Fairmont%20Makati&lat=14.5513&long=121.0231&radius=100&unit=KM"
    },
    {
        name: "SKAI – Swissôtel The Stamford, Singapore",
        image: sg_skai_swissotel,
        className: "img-455",
        link: "https://www.accorplus.com/sg/restaurants/?destination=Swiss%C3%B4tel%20The%20Stamford&lat=1.293196&long=103.852811&radius=100&unit=KM"
    },
    {
        name: "Belga Rooftop Bar – Sofitel Bangkok Sukhumvit, Thailand",
        image: th_belga_rooftop_bar,
        className: "img-400-top",
        link: "https://www.accorplus.com/sg/restaurants/?destination=BELGA%20ROOFTOP%20BAR%20&%20BRASSERIE&lat=13.73977&long=100.55769&radius=100&unit=KM"
    },
    {
        name: "Mezz Restaurant – Sofitel Saigon Plaza, Vietnam",
        image: vn_mezz_restaurant,
        className: "img-400",
        link: "https://www.accorplus.com/sg/restaurants/?destination=MEZZ%20RESTAURANT&lat=10.784133&long=106.702577&radius=100&unit=KM"
    }
]