import peppers_soul from '../../images/amex/peppers-soul-surfers-slider.jpg'
import movenpick_boracay from '../../images/amex/movenpic-boracay-slider.jpg'
import pullman_orchard from '../../images/amex/pullman-singapore-orchard-slider.jpg'
import pullman_phuket from '../../images/amex/pullman-phuket-arcadia-slider.jpg'
import novotel_bangkok from '../../images/amex/Novotel-Bangkok-Platinum.jpg'
import swissotel_syd from '../../images/amex/swissotel-sydney-australia-slider.jpg'
import legacy_vietnam from '../../images/amex/legacy-yen-tu-slider.jpg'
import angsana_teluk from '../../images/amex/angsana-teluk-bahang-slider.jpg'
import fairmont_jaipur from '../../images/amex/Fairmont-jaipur-slider.jpg'
import tribe_bali from '../../images/amex/tribe-bali-kuta-beach-slider.jpg'


export const HOTELS = [
    {
        name: "Fairmont Jaipur – India",
        image: fairmont_jaipur,
        className: "img-400",
        link: "https://www.accorplus.com/sg/hotels/?q=Fairmont%20Jaipur&rooms=1&freenight=NO&room1Adults=1"

    },
    {
        name: "Tribe Bali Kuta Beach – Indonesia",
        image: tribe_bali,
        className: "img-455",
        link: "https://www.accorplus.com/sg/hotels/?q=Tribe%20Bali%20Kuta%20Beach%20&rooms=1&freenight=NO&room1Adults=1"
    },
    { 
        name: "Peppers Soul Surfers Paradise – Australia",
        image: peppers_soul,
        className: "img-455",
        link: "https://www.accorplus.com/sg/hotels/?q=Peppers%20Soul%20Surfers%20Paradise&rooms=1&freenight=NO&room1Adults=1"
    },
    {
        name: "Mövenpick Boracay – Philippines",
        image: movenpick_boracay,
        className: "img-400-top",
        link: "https://www.accorplus.com/sg/hotels/?q=Boracay,%20Malay,%20Philippines&rooms=1&freenight=NO&room1Adults=1"
    },
    {
        name: "Pullman Singapore Orchard – Singapore",
        image: pullman_orchard,
        className: "img-400",
        link: "https://www.accorplus.com/sg/hotels/?q=Pullman%20Singapore%20Orchard&rooms=1&freenight=NO&room1Adults=1"
    },
    {
        name: "Pullman Phuket Arcadia Naithon Beach – Thaïland",
        image: pullman_phuket,
        className: "img-455",
        link: "https://www.accorplus.com/sg/hotels/?q=Pullman%20Phuket%20Arcadia%20Naithon%20Beach&rooms=1&freenight=NO&room1Adults=1"
    },
    {
        name: "Novotel Bangkok Platinum Pratunam – Thaïland",
        image: novotel_bangkok,
        className: "img-400-top",
        link: "https://www.accorplus.com/sg/hotels/?q=Novotel%20Bangkok%20Platinum%20Pratunam&rooms=1&freenight=NO&room1Adults=1"
    },
    {
        name: "Swissôtel Sydney – Australia",
        image: swissotel_syd,
        className: "img-400",
        link: "https://www.accorplus.com/sg/hotels/?q=Swissotel%20Sydney&rooms=1&freenight=NO&room1Adults=1"
    },
    {
        name: "Legacy Yen Tu MGallery – Vietnam",
        image: legacy_vietnam,
        className: "img-455",
        link: "https://www.accorplus.com/sg/hotels/?q=Legacy%20Yen%20Tu%20-%20MGallery&rooms=1&freenight=NO&room1Adults=1"
    },
    {
        name: "Angsana Teluk Bahang – Malaysia",
        image: angsana_teluk,
        className: "img-400-top",
        link: "https://www.accorplus.com/sg/hotels/?q=Angsana%20Teluk%20Bahang&rooms=1&freenight=NO&room1Adults=1"
    }  
]