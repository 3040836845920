import React from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import ReactDOM from 'react-dom';

import VantageHome from './components/vantage/Home';
import Subaru from './components/subaru/Home';
import StandardCharteredBank from './components/standard-chartered-bank/Home';
import AmexSG from './components/amex/Home';


class App extends React.Component {

  render() {  
      return (
          <Router>
          <Switch>
              <Route path="/vantage" exact component={VantageHome}/>
              {/* <Route path="/subaru" exact component={Subaru}/> */}
              <Route path="/test-partner" exact component={StandardCharteredBank}/>
              <Route path="/beyond" exact component={StandardCharteredBank}/>
              <Route path="/amexsiabusiness" exact component={AmexSG}/>
          </Switch>
      </Router>
      );
    }
  }
  
ReactDOM.render(<App />, document.getElementById('root'));
    

