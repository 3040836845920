import React, {Component} from 'react';
import axios from 'axios';
import Header from '../Header';
import { Accordion, Card } from "react-bootstrap";
import parse from 'html-react-parser';
import LoadingScreen from '../common/loading-screen';
import loader from '../../images/site-loader.gif'
// import partners from '../../images/STBAPLockup_V2.jpg';
// import cardFace from '../../images/card-face.png';

import {BIN_CHECK, AMEX_BENEFITS, AMEX_LEAD_CODE_CHECK} from '../../constants/index';
import { validEmail, getUrl, validCode, validBIN, validVoucherCode } from '../helper';

import '../../css/style.css';
import '../../css/amex.css';
import Benefits from './Benefits';
import ProductCard from './ProductCard';
import TravelCarousel from './AmexCarousel';
import { HOTELS } from "./hotels";
import { RESTAURANTS } from "./restaurants";

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            code: '',
            codeValid: true,
            bin: '',
            errorMessage: '',
            successMessage: '',
            loading: false
        }

        this.handleChange   = this.handleChange.bind(this);
        this.handelClick   = this.handelClick.bind(this);
    }
        
    handleChange = (e) => {
        const {name, value} = e.target
        this.setState({
            [name]: value,
            errorMessage: '',
        })
    };

    handelClick = () => {
        this.setState({ loading : true });

        if(!this.state.code){
            this.setState({ 
                loading      : false,
                code: '',
                errorMessage : 'Redemption Code is required.'
            });
            return;
        }
        if(!this.state.bin){
            this.setState({
                loading: false,
                bin: '',
                errorMessage: 'BIN is required.'
            })
            return;
        }
        if(!validVoucherCode(this.state.code)){
            this.setState({
                loading: false,
                errorMessage: "ErrorCode (1032): The unique redemption code is not valid. Please contact our <a href='https://help.accorplus.com/hc/en-au'>Member Services</a> team if you need assistance."
            })
            return;
        }
        if(validBIN(this.state.bin)){
            this.setState({
                loading: false,
                errorMessage: "ErrorCode (1033) : The first 6 digits of your credit card is not valid. Please contact our <a href='https://help.accorplus.com/hc/en-au'>Member Services</a> team if you need assistance."
            })
            return;
        }
        axios.post(`${BIN_CHECK}`,{
            partnerName: "AMEX_SG",
            bin: this.state.bin
            })
            .then(response => {
                console.log('response', response.data);
                if (response.data.statusCode === 200) {
                    axios.post(AMEX_LEAD_CODE_CHECK,{
                        code: this.state.code
                    }).then(response => {
                        console.log("Code check", response)
                        if(response.data.coupon_code){
                            const {member_id} = response.data.member_data
                            const urlRoot = getUrl().urlRoot;
                            const bc = getUrl().amex_bc;
                            const url =  urlRoot + "sg/registration?redirect=F&bc="+bc+"&coupon="+response.data.coupon_code+"&leadid="+member_id;
                            console.log("URL", url);
                            window.location.href = url;
                            }
                    }).catch(err => {
                        console.log('Error fetching code', err.response)
                        // this.setState({
                        //     errorMessage: "Unable to Verify Redemption Code"
                        // })
                        this.setState({
                            errorMessage: "ErrorCode (1032): The unique redemption code is not valid. Please contact our <a href='https://help.accorplus.com/hc/en-au'>Member Services</a> team if you need assistance."
                        })
                    })
                    // if(response.data.coupon_code){
                    //     const urlRoot = getUrl().urlRoot;
                    //     const bc = getUrl().subaru_bc;
                    //     const url =  urlRoot + "au/registration?redirect=F&bc="+bc+"&email="+this.state.email+"&coupon="+response.data.coupon_code;
                    //     console.log("URL", url);
                    //     window.location.href = url;
                    // }

                    this.setState({ loading : false });
                } else {
                    switch(response.data.statusCode){
                        case 400:
                            this.setState({errorMessage: `ErrorCode (1027): We can't seem to proceed... Please contact our <a href='https://help.accorplus.com/hc/en-au'>Member Services</a> team for further assistance`})
                            break;
                        case 401:
                            this.setState({errorMessage: `ErrorCode (1032): The unique redemption code is not valid. Please contact our <a href='https://help.accorplus.com/hc/en-au'>Member Services</a> team if you need assistance.`})
                            break;
                        case 404:
                            this.setState({errorMessage: `ErrorCode (1028): We can't seem to proceed... Please contact our <a href='https://help.accorplus.com/hc/en-au'>Member Services</a> team for further assistance`})
                            break;
                        case 500:
                            this.setState({errorMessage: `ErrorCode (1029): We can't seem to proceed... Please contact our <a href='https://help.accorplus.com/hc/en-au'>Member Services</a> team for further assistance`})
                            break;
                        default:
                            this.setState({errorMessage: "Oops! Something went wrong, Please try again"})
                    }
                    this.setState({ loading: false })
                }
            }).catch(err => {
                if(err.response.status === 403){
                    this.setState({errorMessage: "It appears that you are logged in as a member. Please contact Member Services for assistance --> we need to hyperlink 'Member Services' so that help is intuitive"})
                }
            })
    };

    componentDidMount(){
        document.title = "American Express Singapore - Accor Plus Singapore"
    }


    render() {
        const {errorMessage, emailValid, loading, successMessage} =this.state;
        var benefits;
        if(AMEX_BENEFITS){
            benefits = AMEX_BENEFITS
            console.log(benefits)
        }
        var errrorClass=""; 
        if(!this.state.codeValid){
            errrorClass = "validation-error"
        }
        return (
            <div class="hotelSales-wrapper">
                <Header 
                    handleLeaderBoard = {this.handleLeaderBoard}
                    bannerClass = "banner-amex"/> 
                <div className="container">
                    <div className="d-flex mx-auto">
                        <div class="mt-5 container px-0 mx-0">
                            <h2 className="montserrat-medium tracking-widest">Redeem your complimentary Accor Plus Explorer membership worth SGD 418, Courtesy of
                            American Express Singapore</h2>
                            <p className="amex-content">As an American Express<sup className="text-dark">®</sup> Singapore Airlines Business Credit Card Member, enjoy a complimentary Accor Plus Explorer membership worth SGD 418.
                            Accor Plus, Asia Pacific’s most expansive travel, dining and lifestyle programme enables you to do more, in Singapore and across Asia Pacific. Enjoy exclusive member benefits at over 1,000 participating Accor hotels and resorts including international hotel brands such as Pullman, Sofitel, Swissôtel and more.</p>
                            
                            <p className="amex-content">To redeem the complimentary Accor Plus Explorer membership, enter the first 6 digits of your American Express Singapore Airlines Business Credit Card and the unique code printed on the voucher you received from American Express.</p>
                        </div>
                        {/* <div class="mt-5 brand-container col-4"> */}
                            {/* SCB | Accorplus Logo */}
                            {/* <div className="partners-card d-flex flex-column justify-content-center">
                                <div className="card-face">
                                    <img src={cardFace} width="420px"/>
                                </div>
                                <img src={partners} width="100%"/>
                            </div>
                        </div> */}
                    </div>
                    

                    <div className="container">
                        {/* <h5 class="px-0 py-3 text-center text-bold">Enter your unique redemption code to start experiencing more:</h5> */}
                        <div class="py-4">
                            <input type="text" name="bin" class={`email-check-input ${errrorClass}`} placeholder="First 6 digits of your Amex Card" onChange={this.handleChange}></input>
                            <input type="text" name="code" class={`email-check-input mt-2 ${errrorClass}`} placeholder="Enter voucher code" onChange={this.handleChange}></input>
                            {successMessage ? (
                                <div className="my-2 text-bold text-center">
                                    {successMessage}
                                </div>
                            ) : errorMessage ? (
                                <div className="my-2 text-bold text-center text-danger" dangerouslySetInnerHTML={{ __html: this.state.errorMessage }}>
                                </div>
                            ) : null}
                            {loading ? (
                                // <LoadingScreen/>
                                <img src={loader} alt="loading" className="d-flex justify-center text-center mx-auto"/>
                            ) : (
                                <button class="email-check-input-submit btn btn-primary mt-2 button theme-primary" onClick={this.handelClick}>Next <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="icon"><path d="M17.42 10l-6.951-6.95-1.326 1.325 4.687 4.688H2.97v1.874H13.83l-4.687 4.688 1.326 1.326L17.419 10z"></path></svg></button>
                            )}
                        </div>
                    </div>
                    <div className="mt-5">
                        <h2 className="title mb-3">Membership Types and Benefits</h2>
                        <div className="row mt-2 d-flex justify-content-between">
                            <div className="container accordion-container benefits-container mb-5 col-6">
                                {/* <Accordion defaultActiveKey="0"> */}
                                    <Benefits benefits={benefits}/>
                                {/* </Accordion> */}
                            </div>
                            <div className="col-6">
                                <ProductCard/>
                            </div>    
                        </div>
                    </div>

                    <div className="container mt-2 mb-5">
                        <h2 className="title" style={{color: '#050033'}}><strong>Important Information:</strong></h2>
                        
                        <ul className="amex-list">
                         <li>This benefit is only applicable for eligible American Express Singapore Airlines Business Credit Card Members as determined by American Express.</li>
                         <li>You will need to provide your personal information (including a valid email address) directly to Accor Plus for enrolment to enjoy the complimentary Accor Plus membership. A valid email address refers to an email address that is not currently linked to an existing Accor Plus membership account.</li>
                         <li>Complimentary membership is valid until the expiry date stated on the voucher issued by American Express.</li>
                        </ul>
                    </div>

                    <div class="container amex-terms_conditions mt-4">
                        <h2 class="title" style={{color: '#050033'}}><strong>Accor Plus Terms and conditions:</strong></h2>
                        <ul className="amex-list">
                        <li>Each eligible American Express Singapore Airlines Business Credit Card Member is entitled to an Accor Plus membership that includes 1 Stay Plus complimentary night stay, and other dining and lifestyle member benefits.</li>
                        {/* <p>- Eligible participants for this promotion are:</p>
                        <ul>
                            <li>(i) Individuals who bought a new vehicle from Subaru (Excludes MY23 Forester Hybrid, MY24 Subaru Crosstrek Hybrid and MY24 Subaru Impreza. Offer excludes Fleet, Government, and rental buyers) and delivered between 26 December 2023 and 29 February 2024;</li>
                            <li>(ii) Aged 18 years and above;</li>
                            <li>(iii) Residents of Australia only.</li>
                        </ul> */}

                        <li>Accor Plus membership cannot be exchanged for cash, rewards points, or any other item.</li>
                        
                        <li>Each eligible American Express Singapore Airlines Business Credit Card Member will also automatically qualify for a minimum of Elite Silver membership status in ALL – Accor Live Limitless, Accor’s global lifestyle loyalty programme.</li>
                        <li>The full list of participating Accor properties across Asia Pacific can be found at <a href="https://www.accorplus.com/">accorplus.com</a>.</li>
                        <li>All Accor Plus member benefits, including 1 Stay Plus complimentary night, will be valid based on the expiry date stated on the voucher issued by American Express. Member benefits cannot be retroactively accrued.</li>
                        <li>Standard terms and conditions governing the use of an Accor Plus Explorer membership apply. Please view the<a href="https://www.accorplus.com/sg/about/terms-and-conditions/"> Accor Plus membership terms and conditions</a> for more details. T&Cs apply and are subject to changes without prior notice.</li>
                        <li>After activating your membership, download the Accor ALL.com app to access your digital Accor Plus card to enjoy your member benefits straight away.</li>
                        <li><a href="https://www.accorplus.com/sg/stay-benefits-exceptions/">Stay</a> or <a href="https://www.accorplus.com/sg/dining-benefits-exceptions">dining</a> exceptions may apply to the standard Accor Plus member benefits.</li>
                        <li>For inquiries on your Accor Plus membership, please contact the Accor Plus Member Services team via the <a href="https://accorplushelp.zendesk.com/hc/en-au" target="_blank" rel="noopener">Member Help Centre</a>.</li>
                        {/* <li>Standard terms and conditions governing the use of an Accor Plus Explorer membership apply. Please view the Accor Plus membership <a href="https://www.accorplus.com/sg/about/terms-and-conditions/">terms and conditions</a> for more details. T&C’s apply and subject to changes without prior notice</li>
                        <li>For existing Accor Plus members, please reach out to the <a href="https://help.accorplus.com/hc/en-au?_gl=1*xaag6n*_gcl_au*MTA3MDQ0MzcxNC4xNzM4ODIwNDMx">Accor Plus Member Services Team</a> to assist you in registration.</li> */}
                        <p class='hidden'>
                            testing cache
                        </p>
                       </ul>     
                    </div>
                </div>
                <div className="my-5">
                    <TravelCarousel items={HOTELS} title="Uncover a whole new travel experience" type="Hotel" rtl={false}/>
                </div>
                
                <div className="my-5">
                    <TravelCarousel items={RESTAURANTS} title="Unlock a whole new world of flavours" type="Restaurant" rtl={true}/>
                </div>
                
                <div className="container mt-5">
                    <p className="amex-content">Your journey to more experiences begins with Accor Plus. Access incredible savings on your next trip, staycation, or dining at your favourite Accor hotel like Sofitel, Pullman, Fairmont and many more.</p>
                </div>
            </div>    
        );
    }
}

export default (Home);
