export const GLOBALHEADER_MESSAGE = "GLobal Header Message";

//API URL
var urlPath = window.location.href;
let base_url = "https://api-uat.accorplus.com/";
if(urlPath.indexOf("localhost") !== -1) {
    base_url = "https://api-uat.accorplus.com/";
} else if(window.location.host == "partners.accorplus.com" || window.location.host == "accorplus.com" || window.location.host == "www.accorplus.com" || window.location.host == "nprod.accorplus.com") {
    base_url = "https://int-api.accorplus.com/";
}

export const DBS_LEAD_EMAIL_CHECK = base_url + "v1/partner/dbsleademailcheck";
export const SUBARU_LEAD_EMAIL_CHECK = base_url + "v1/partner/subaruleademailcheck";
export const SCB_LEAD_EMAIL_CHECK = base_url + "v1/partner/scbleadcodecheck"
export const AMEX_LEAD_CODE_CHECK = base_url + "v1/partner/amexleadcodecheck"
export const BIN_CHECK = base_url + "v1/partner/bin/verify"

export const HEADERAPIVALUE = "hTrmOb88z68c4LOF5huSk698AdlV9mqK4asLDY6o";
export const GLOBALAPIHEADER = {
    headers: {
        'x-api-key': 'hTrmOb88z68c4LOF5huSk698AdlV9mqK4asLDY6o'
    }
};

export const DBS_BENEFITS = [
    {
        'title' : 'One (1) STAY PLUS COMPLIMENTARY NIGHT',
        'description' : '<p>Courtesy of DBS Vantage Card, you are entitled to one (1) Stay Plus complimentary night.</p><p>Enjoy one (1) complimentary night at over 1,000 hotels across Asia Pacific. From city to sea and economy to luxury, use your benefit to stay for free.</p>'
    },
    {
        'title' : 'UP TO 50% OFF DINING ACROSS 1,400 RESTAURANTS IN ASIA PACIFIC',
        'description' : 'Savour culinary delights from around the world at over 1,400 hotel restaurants across 20 countries. Your Accor Plus membership brings you up to 50% off the food bill and 15% off drinks in Asia.'
    },
    {
        'title' : "EXCLUSIVE MEMBERS' RATE OF 10% OFF THE BEST AVAILABLE PUBLIC ROOM RATE",
        'description' : "Enjoy more nights for less by accessing your exclusive Accor Plus Members' Rate all year round. Check out the hotel list or the Accor All app and book your next trip on your Members' Rate."
    },
    {
        'title' : 'MEMBERS EXCLUSIVE MORE ESCAPES PACKAGES',
        'description' : 'Unwind with Accor Plus member exclusive packages. From all-inclusive dining to spa treatments, our More Escapes packages include everything you need for an unforgettable holiday.'
    },
    {
        'title' : 'ACCESS TO RED HOT ROOMS WHERE DISCOUNTS CAN BE UP TO 50% OFF',
        'description' : 'Save up to 50% on your hotel or resort accommodation with our Red Hot Room offers. Exclusive to Accor Plus members, Red Hot Rooms offer great savings on a wide selection of Accor hotels.'
    },
    {
        'title' : 'EARLY ACCESS TO ACCOR HOTEL SALE',
        'description' : 'In addition, your Accor Plus membership gives you early access to Accor Hotel sales. Not only will you access the Accor Super and Private sales one day in advance, you will also enjoy an additional 10% off the promotion rate.'
    },
    {
        'title' : 'BE REWARDED WITH ELITE STATUS AND A BONUS OF 20 STATUS NIGHTS',
        'description' : "Automatically qualify for Elite status in ALL – Accor Live Limitless, Accor’s global lifestyle loyalty programme, and enjoy a bonus of 20 Status Nights for quicker access to the next status level or to retain your current status for longer."
    }
];

export const SUBARU_BENEFITS = [
    {
        'title' : "10% OFF PUBLIC ROOM RATE",
        'description' : "Enjoy more nights for less by accessing your exclusive Accor Plus Members' Rate all year round. Check out the hotel list or the Accor All app and book your next trip on your Members' Rate."
    },
    {
        'title' : 'EARLY ACCESS TO ACCOR HOTEL PROMOTIONS',
        'description' : 'In addition, your Accor Plus membership gives you early access to Accor Hotel sales. Not only will you access the Accor Super and Private sales one day in advance, you will also enjoy an additional 10% off the promotion rate.'
    },
    {
        'title' : 'MEMBERS EXCLUSIVE MORE ESCAPES PACKAGES',
        'description' : 'Unwind with Accor Plus member exclusive packages. From all-inclusive dining to spa treatments, our More Escapes packages include everything you need for an unforgettable holiday.'
    },
    {
        'title' : 'ACCESS TO RED HOT ROOM TO GET UP TO 50% OFF',
        'description' : 'Save up to 50% on your hotel or resort accommodation with our Red Hot Room offers. Exclusive to Accor Plus members, Red Hot Rooms offer great savings on a wide selection of Accor hotels.'
    },
    {
        'title' : 'UP TO 50% OFF YOUR MEAL BILL',
        'description' : 'Savour culinary delights from around the world at over 1,400 hotel restaurants across 20 countries. Your Accor Plus membership brings you up to 50% off the food bill and 15% off drinks in Asia.'
    },
    {
        'title' : 'UP TO 15% OFF YOUR DRINKS BILL IN ASIA',
        'description' : 'Your Accor Plus membership brings you 15% off drinks in Asia.'
    },
    {
        'title' : 'ENJOY EXCLUSIVE ACCESS TO MEMBER ONLY EVENTS',
        'description' : 'Meet and greet your sporting idols, congratulate the cast members of the latest theatre sensation in person, learn how to slice, dice and sauté with a Masterchef. Do things you’ve never done before – thanks to your Accor Plus membership.'
    },  
    {
        'title' : 'BE REWARDED WITH ELITE STATUS AND A BONUS OF 20 STATUS NIGHTS',
        'description' : "Automatically qualify for elite status in all – accor live limitless, accor’s global lifestyle loyalty programme, and enjoy a bonus of 20 status nights for quicker access to the next status level or to retain your current status for longer."
    }
];

export const SCB_BENEFITS = [
    {
        'title' : 'Two (2) STAY PLUS COMPLIMENTARY NIGHTS',
        'description' : '<p>Courtesy of Standard Chartered Beyond Card, you are entitled to two (2) Stay Plus complimentary nights.</p><p>Enjoy two (2) complimentary nights at over 1,000 hotels across Asia Pacific, From city to sea and economy to luxury, use your benefit to stay for free.</p>'
    },
    {
        'title' : 'UP TO 50% OFF DINING ACROSS 1,400 RESTAURANTS IN ASIA PACIFIC',
        'description' : 'Savour culinary delights from around the world at over 1,400 hotel restaurants across 20 countries. Your Accor Plus membership brings you up to 50% off the food bill and 15% off drinks in Asia.'
    },
    {
        'title' : "EXCLUSIVE MEMBERS' RATE OF 10% OFF THE BEST AVAILABLE PUBLIC ROOM RATE",
        'description' : "Enjoy more nights for less by accessing your exclusive Accor Plus Members' Rate all year round. Check out the hotel list or the Accor All app and book your next trip on your Members' Rate."
    },
    {
        'title' : 'MEMBERS EXCLUSIVE MORE ESCAPES PACKAGES',
        'description' : 'Unwind with Accor Plus member exclusive packages. From all-inclusive dining to spa treatments, our More Escapes packages include everything you need for an unforgettable holiday.'
    },
    {
        'title' : 'ACCESS TO RED HOT ROOMS WHERE DISCOUNTS CAN BE UP TO 50% OFF',
        'description' : 'Save up to 50% on your hotel or resort accommodation with our Red Hot Room offers. Exclusive to Accor Plus members, Red Hot Rooms offer great savings on a wide selection of Accor hotels.'
    },
    {
        'title' : 'EARLY ACCESS TO ACCOR HOTEL SALES',
        'description' : 'In addition, your Accor Plus membership gives you early access to Accor Hotel sales. Not only will you access the Accor Super and Private sales one day in advance, you will also enjoy an additional 10% off the promotion rate.'
    },
    {
        'title' : 'BE REWARDED WITH ELITE STATUS AND A BONUS OF 20 STATUS NIGHTS',
        'description' : "Automatically qualify for Elite status in ALL – Accor Live Limitless, Accor’s global lifestyle loyalty programme, and enjoy a bonus of 20 Status Nights for quicker access to the next status level or to retain your current status for longer."
    }
];

export const AMEX_BENEFITS = [
    {
        "title": "MORE Stays",
        "items": [
          {
            "name": "bed",
            "title": "10% off public room rate",
            "description": "Enjoy more nights for less by accessing your exclusive Accor Plus member’s rate all year round. Check out the hotel list and book your next trip on your member’s rate."
          },
          {
            "name": "promo",
            "title": "Early access to Accor hotel promotions",
            "description": "In addition, your Accor Plus membership gives you early access to Accor hotel sales. Not only will you access the Accor sales one day in advance, you will also enjoy an additional 10% off the promotion rate."
          },
          {
            "name": "vacation",
            "title": "Member exclusive More Escapes packages",
            "description": "Unwind with Accor Plus member exclusive packages. From all-inclusive dining to spa treatments, More Escapes packages include everything you need for an unforgettable holiday."
          },
          {
            "name": "city",
            "title": "Access to Red Hot Room to get up to 50% off",
            "description": "Save up to 50% on your hotel or resort accommodation with Red Hot Room offers. Exclusive to Accor Plus members, Red Hot Rooms offer great savings on a wide selection of Accor hotels."
          }
        ]
    },
    {
        "title": "MORE Dining",
        "items": [
          {
            "name": "dine",
            "title": "Up to 50% off your meal bill",
            "description": "Savour culinary delights from around the world at over 1,400 hotel restaurants across 20 countries. Your Accor Plus membership brings you up to 50% off the food bill."
          },
          {
            "name": "drinks",
            "title": "15% off your drinks bill in Asia",
            "description": "Your Accor Plus membership brings you 15% off drinks in Asia."
          }
        ]
      },
      {
        "title": "MORE Experiences",
        "items": [
          {
            "name": "heart",
            "title": "Enjoy exclusive access to member only events",
            "description": "Meet and greet your sporting idols, congratulate the cast members of the latest theatre sensation in person, learn how to slice, dice and sauté with a Masterchef. Do things you’ve never done before – thanks to your Accor Plus membership."
          }
        ]
      },
      {
        "title": "MORE Rewards",
        "items": [
          {
            "name": "all",
            "title": "Be rewarded with Elite status and a bonus of 20 Status Nights",
            "description": "Automatically qualify for Elite status in ALL – Accor Live Limitless, Accor’s global lifestyle loyalty programme, and enjoy a bonus of 20 Status Nights for quicker access to the next status level or to retain your current status for longer."
          }
        ]
      }
];

export const DOMAINS = ["au","id","vn","th","my"];

