import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "./AmexCarousel.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TravelCarousel = ({ items, title, type, rtl }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);  
  let sliderRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);
  console.log(windowWidth)
  
  console.log("Current Index:", currentIndex);

  const settings = {
    className: "ml-4 overflow-hidden",
    infinite: true,
    slidesToShow: windowWidth <= 1280 ? 4 : windowWidth <= 1850 ? 5 : 6,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    initialSlide: 0,
    rtl: rtl,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMode: true,
          // centerPadding: "20px",
          slidesToShow: windowWidth <= 800 ? 2 : 3,
          slidesToScroll: 1, 
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          // centerMode: true,
          slidesToShow: 2,
          slidesToScroll: 1, 
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          // centerPadding: "40px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: (_, nextIndex) => {
      console.log("Next slide index:", nextIndex);
      setCurrentIndex(nextIndex);
    },
};

  return (
    <div className="my-4 carousel-container">
      <h2 className="text-uppercase montserrat-medium text-center my-3">{title}</h2>
      <div className="item-container">
        <Slider ref={sliderRef} {...settings}>
          {items.map((item, index) => (
            <div key={index} className="slick-slide">
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                <div
                  className={`item-card mr-3 mt-3 ${
                    index === currentIndex ? "active-slide" : ""
                  }`}
                >
                  <img
                    src={item.image}
                    alt={item.name}
                    className={`item-image ${index === currentIndex ? "active-image" : ""} ${item.className}`}
                  />
                  <div className="slide-item-title mt-2">
                    <span className="montserrat-medium">
                      <span className="hover-text">
                        {`View ${type}`}{" "}
                        <span
                          className={type === "Hotel" ? "hotel-underline" : "restaurant-underline"}
                        ></span>
                      </span>
                      <span className="text-uppercase item-name">{item.name}</span>
                    </span>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </Slider>
        <div className="pagination-container container">
          {items.map((item, index) => (
            <div key={index}>
              <button
                className={`btn item-pagination ${currentIndex === index ? "active-pagination" : ""}`}
                onClick={() => sliderRef.current?.slickGoTo(index)}
              ></button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TravelCarousel;
