import React, {Component} from 'react';
import axios from 'axios';
import Header from '../Header';
import { Accordion, Card } from "react-bootstrap";
import parse from 'html-react-parser';
import LoadingScreen from '../common/loading-screen';
import loader from '../../images/site-loader.gif'
import partners from '../../images/STBAPLockup_V2.jpg';
import cardFace from '../../images/card-face.png';

import {BIN_CHECK, SCB_BENEFITS, SCB_LEAD_EMAIL_CHECK} from '../../constants/index';
import { validEmail, getUrl, validCode, validBIN } from '../helper';

import '../../css/style.css';

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            code: '',
            codeValid: true,
            bin: '',
            errorMessage: '',
            successMessage: '',
            loading: false
        }

        this.handleChange   = this.handleChange.bind(this);
        this.handelClick   = this.handelClick.bind(this);
    }
        
    handleChange = (e) => {
        const {name, value} = e.target
        this.setState({
            [name]: value,
            errorMessage: '',
        })
    };

    handelClick = () => {
        this.setState({ loading : true });

        if(!this.state.code){
            this.setState({ 
                loading      : false,
                code: '',
                errorMessage : 'Redemption Code is required.'
            });
            return;
        }
        if(!this.state.bin){
            this.setState({
                loading: false,
                bin: '',
                errorMessage: 'BIN is required.'
            })
            return;
        }
        if(!validCode(this.state.code)){
            this.setState({
                loading: false,
                errorMessage: "ErrorCode (1032): The unique redemption code is not valid. Please contact our <a href='https://help.accorplus.com/hc/en-au'>Member Services</a> team if you need assistance."
            })
            return;
        }
        if(validBIN(this.state.bin)){
            this.setState({
                loading: false,
                errorMessage: "ErrorCode (1033) : The first 6 digits of your credit card is not valid. Please contact our <a href='https://help.accorplus.com/hc/en-au'>Member Services</a> team if you need assistance."
            })
            return;
        }
        // if(!validEmail(this.state.code)){
        //     this.setState({ 
        //         loading      : false,
        //         codeValid   : false,
        //         errorMessage : 'Redemption Code not valid.'});
        //     return;
        // }
        axios.post(`${BIN_CHECK}`,{
            partnerName: "SCB",
            bin: this.state.bin
            })
            .then(response => {
                console.log('response', response.data);
                if (response.data.statusCode === 200) {
                    axios.post(SCB_LEAD_EMAIL_CHECK,{
                        code: this.state.code
                    }).then(response => {
                        console.log("Code check", response)
                        if(response.data.coupon_code){
                            const {member_id} = response.data.member_data
                            const urlRoot = getUrl().urlRoot;
                            const bc = getUrl().scb_bc;
                            const url =  urlRoot + "sg/registration?redirect=F&bc="+bc+"&coupon="+response.data.coupon_code+"&leadid="+member_id;
                            console.log("URL", url);
                            window.location.href = url;
                            }
                    }).catch(err => {
                        console.log('Error fetching code', err.response)
                        // this.setState({
                        //     errorMessage: "Unable to Verify Redemption Code"
                        // })
                        this.setState({
                            errorMessage: "ErrorCode (1032): The unique redemption code is not valid. Please contact our <a href='https://help.accorplus.com/hc/en-au'>Member Services</a> team if you need assistance."
                        })
                    })
                    // if(response.data.coupon_code){
                    //     const urlRoot = getUrl().urlRoot;
                    //     const bc = getUrl().subaru_bc;
                    //     const url =  urlRoot + "au/registration?redirect=F&bc="+bc+"&email="+this.state.email+"&coupon="+response.data.coupon_code;
                    //     console.log("URL", url);
                    //     window.location.href = url;
                    // }

                    this.setState({ loading : false });
                } else {
                    switch(response.data.statusCode){
                        case 400:
                            this.setState({errorMessage: `ErrorCode (1027): We can't seem to proceed... Please contact our <a href='https://help.accorplus.com/hc/en-au'>Member Services</a> team for further assistance`})
                            break;
                        case 401:
                            this.setState({errorMessage: `ErrorCode (1032): The unique redemption code is not valid. Please contact our <a href='https://help.accorplus.com/hc/en-au'>Member Services</a> team if you need assistance.`})
                            break;
                        case 404:
                            this.setState({errorMessage: `ErrorCode (1028): We can't seem to proceed... Please contact our <a href='https://help.accorplus.com/hc/en-au'>Member Services</a> team for further assistance`})
                            break;
                        case 500:
                            this.setState({errorMessage: `ErrorCode (1029): We can't seem to proceed... Please contact our <a href='https://help.accorplus.com/hc/en-au'>Member Services</a> team for further assistance`})
                            break;
                        default:
                            this.setState({errorMessage: "Oops! Something went wrong, Please try again"})
                    }
                    this.setState({ loading: false })
                }
            }).catch(err => {
                if(err.response.status === 403){
                    this.setState({errorMessage: "It appears that you are logged in as a member. Please contact Member Services for assistance --> we need to hyperlink 'Member Services' so that help is intuitive"})
                }
            })
    };

    render() {
        const {errorMessage, emailValid, loading, successMessage} =this.state;
        var benefits;
        if(SCB_BENEFITS){
            benefits = SCB_BENEFITS.map((item, index) =>
                    <Card key={index} className="benefits">
                        <Accordion.Toggle as={Card.Header} eventKey={index+1}>
                            <div className="row d-flex ">
                                <div className="mb-0 col-10 col-md-11 accordion-title-text">
                                { item.title}
                                </div>
                                <div className="mb-0 col-2 col-md-1">
                                    <img alt= "plus-symbol" src="/images/plus-symbol.png" width="22" />
                                </div>   
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={index+1}>
                            <Card.Body>
                                <div className="row">
                                    <div className="col-12">
                                        {parse(item.description)}
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                );
        }
        var errrorClass=""; 
        if(!this.state.codeValid){
            errrorClass = "validation-error"
        }
        return (
            <div class="hotelSales-wrapper">
                <Header 
                    handleLeaderBoard = {this.handleLeaderBoard}
                    bannerClass = "banner-scb"/> 
                <div className="container">
                    <div className="d-flex justify-between mx-auto row">
                        <div class="mt-5 container col-8 px-0 mx-0">
                            <h2>Redeem your complimentary Accor Plus Explorer Plus membership worth SGD 538, Courtesy of
                            Standard Chartered Beyond Credit Card</h2>
                            <p>As a Standard Chartered Beyond Credit Card member with a SC Priority Private or Private Banking relationship, you are
                            entitled to a 12-month Accor Plus Explorer Plus membership worth SGD 538.</p>
                            <p>Accor Plus, Asia Pacific&#39;s most expansive travel, dining and lifestyle programme enables you to do
                            more, in Singapore and across Asia Pacific. Enjoy exclusive member benefits at over 1,000
                            participating Accor hotels and resorts including international hotel brands such as Fairmont, Sofitel,
                            Swissôtel, and more.</p>
                            <p>Enjoy two complimentary hotel night stays**, up to 50% off dining across Asia Pacific, 15% off drinks
                            in Asia, access to Members&#39; Rate at 10% off the best available room rate, exclusive member-only
                            offers of up to 50% off, and early access to Accor hotel sales.</p>
                            <p>You will also automatically qualify for a minimum of Elite Silver membership status in ALL – Accor
                            Live Limitless, Accor’s global lifestyle loyalty programme, and enjoy a bonus of 20 Status Nights
                            (worth SGD 2,600** spend in eligible expenses).</p>
                        </div>
                        <div class="mt-5 brand-container col-4">
                            {/* SCB | Accorplus Logo */}
                            <div className="partners-card d-flex flex-column justify-content-center">
                                <div className="card-face">
                                    <img src={cardFace} width="420px"/>
                                </div>
                                <img src={partners} width="100%"/>
                            </div>
                        </div>
                    </div>
                    <div className="container accordion-container mt-5 benefits-container mb-5">
                        <Accordion defaultActiveKey="0">
                            {benefits}
                        </Accordion>
                    </div>

                    <div className="container">
                        <h5 class="px-0 py-3 text-center text-bold">Enter the first 6 digits of your Standard Chartered Beyond Credit Card and <br></br>
                        your unique redemption code to start experiencing more:</h5>
                        <div class="py-3">
                            <input type="text" name="bin" class={`email-check-input ${errrorClass}`} placeholder="First 6 digits of your credit card" onChange={this.handleChange}></input>
                            <input type="text" name="code" class={`email-check-input mt-2 ${errrorClass}`} placeholder="Enter code" onChange={this.handleChange}></input>
                            {successMessage ? (
                                <div className="my-2 text-bold text-center">
                                    {successMessage}
                                </div>
                            ) : errorMessage ? (
                                <div className="my-2 text-bold text-center text-danger" dangerouslySetInnerHTML={{ __html: this.state.errorMessage }}>
                                </div>
                            ) : null}
                            {loading ? (
                                // <LoadingScreen/>
                                <img src={loader} alt="loading" className="d-flex justify-center text-center mx-auto"/>
                            ) : (
                                <button class="email-check-input-submit btn btn-primary mt-2 button theme-primary" onClick={this.handelClick}>Submit <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="icon"><path d="M17.42 10l-6.951-6.95-1.326 1.325 4.687 4.688H2.97v1.874H13.83l-4.687 4.688 1.326 1.326L17.419 10z"></path></svg></button>
                            )}
                        </div>
                    </div>

                    <div class="container terms_conditions mt-4">
                        <p class="title"><strong>Terms and conditions:</strong></p>
                        <p>- *This offer is open to eligible Standard Chartered Beyond Principal cardholders with a SC Priority Private or Private Banking relationship.</p>
                        {/* <p>- Eligible participants for this promotion are:</p>
                        <ul>
                            <li>(i) Individuals who bought a new vehicle from Subaru (Excludes MY23 Forester Hybrid, MY24 Subaru Crosstrek Hybrid and MY24 Subaru Impreza. Offer excludes Fleet, Government, and rental buyers) and delivered between 26 December 2023 and 29 February 2024;</li>
                            <li>(ii) Aged 18 years and above;</li>
                            <li>(iii) Residents of Australia only.</li>
                        </ul> */}

                        <p>- ** Only one (1) Stay Plus night complimentary night can be used per booking. Consecutive nights at
                        the same hotel count as one stay.</p>
                        
                        <p> Eligible Cardholders are entitled to a 12-month Accor Plus Explorer Plus membership from the day of membership enrolment.</p>
                        <p>- Upon successful registration via www.accorplus.com/sg/beyond, your membership will be
                        processed within 2 business days. Be sure to follow the steps in the Welcome E-mail to activate your
                        membership.</p>
                        <p>- After activating your membership, download the Accor ALL app to access your digital Accor Plus
                        card to enjoy your member benefits straight away. Your digital vouchers can be accessed through
                        your membership account on accorplus.com.</p>
                        <p>- Once a redemption has been made, it cannot be cancelled or exchanged for cash, rewards points,
                        or any other item.</p>
                        <p>- Standard terms and conditions governing the use of an Accor Plus Explorer membership apply, and are subject to changes without prior notice. Please view the <a href="https://www.accorplus.com/sg/about/terms-and-conditions/">Accor Plus membership terms and conditions</a> for more details.</p>
                        <p>- If you have a current Accor Plus membership, you may wish to register for your Accor Plus Explorer
                        Plus membership courtesy of Standard Chartered Beyond Credit Card using an unused email
                        address.</p>
                        <p>- Member benefits across multiple Accor Plus memberships cannot be merged or stacked.</p>
                        <p>- Stay or dining exceptions may apply to the standard Accor Plus member benefits. Please refer to the <a href="https://www.accorplus.com/sg/about/terms-and-conditions/">member benefits exception list</a> for more details.</p>
                        <p>- For inquiries on your Accor Plus membership, please contact the Accor Plus Member Services team via the <a href="https://accorplushelp.zendesk.com/hc/en-au" target="_blank" rel="noopener">Member Help Centre</a>.</p>
                        <p class='hidden'>
                            testing cache
                        </p>

                        {/* <p>- DBS Vantage Cardmembers must register their interest for their Accor Plus Explorer membership at <a href="https://dbs.com.sg/vantage" target="_blank">dbs.com.sg/vantage.</a> DBS Vantage Cardmembers will receive a welcome email from Accor Plus within 1 working day once their Accor Plus membership has been successfully registered.</p>
                        <p>- Processing of your Accor Plus Explorer membership will take 2-3 days upon Card open date.</p>
                        <p>- Each Principal DBS Vantage Cardmember is entitled to a 12-month Accor Plus Explorer membership per Card Year. The Accor Plus Explorer membership includes 1 Stay Plus complimentary night stay and other dining and lifestyle member benefits.</p>
                        <p>- Each Principal DBS Vantage Cardmember will also automatically qualify for a minimum of Elite Silver membership status in ALL - Accor Live Limitless, Accor's global lifestyle loyalty programme. This status will be reflected in your Accor ALL App, where you get to redeem the one complimentary night stay (known as Stay Plus).</p>
                        <p>- The full list of participating Accor properties across Asia Pacific can be found at accorplus.com.</p>
                        <p>- All Accor Plus member benefits, including 1 Stay Plus complimentary night, will be valid per Card Year. None of the member benefits will be retroactively accrued.</p>
                        <p>- Standard terms and conditions governing the use of an Accor Plus Explorer membership apply. Please view the <a href="https://www.accorplus.com/sg/about/terms-and-conditions/">Accor Plus membership terms and conditions</a> for more details.</p>    
                        <p>- Stay or dining exceptions may apply to the standard Accor Plus member benefits, please refer to the <a href="https://www.accorplus.com/sg/about/terms-and-conditions/">member benefits exception list</a> for more details.</p>    
                        <p>- For any enquiries on Accor Plus membership, please contact the Accor Plus Member Services team via the <a href="https://accorplushelp.zendesk.com/hc/en-au" target="_blank" rel="noopener">Member Help Centre</a>.</p>     */}
                    </div>
                </div>
            </div>    
        );
    }
}

export default (Home);
